<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Test_Records') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="hiddenColTable"
                            :items="resultsSections"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoadingResultsSection"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:[`item.is_pass`]="{ item }">
                                <div class="my-2">
                                    <a-tag class="table-tag" color="green" v-if="item.is_pass">
                                        {{ $t('course_detail.pass') }}
                                    </a-tag>
                                    <a-tag class="table-tag" color="red" v-else> {{ $t('course_detail.fail') }} </a-tag>
                                </div>
                            </template>
                            <template v-slot:[`item.user_score`]="{ item }">
                                <v-progress-linear :value="item.user_score || 0" height="25" :style="{ width: '75%' }">
                                    <strong>{{ Math.ceil(item.user_score || 0) }}%</strong>
                                </v-progress-linear>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <router-link
                                        :to="{
                                            path: '/test-record-detail',
                                            query: { test_id: item.test_id, test_attend_id: item.id },
                                        }"
                                    >
                                        <v-btn color="indigo" class="mr-2" dark small>
                                            <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                            {{ $t('cms.detail') }}
                                        </v-btn>
                                    </router-link>
                                </div>
                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <f-pagination
                                v-model="page"
                                :pageSize="resultsSectionsPagination.pageSize"
                                :totalItems="resultsSectionsPagination.totalItems"
                                :disabled="isLoadingResultsSection"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UserModal from '../../components/CMS/Modal/User.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS/Modal/Noti.vue';
import { mixinToasts, mixinFormats } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinToasts, mixinFormats],
    components: { UserModal, ModalDelete, ModalNoti },

    data() {
        return {
            tableTitle: 'Details of Section',
            tableColumns: [
                { text: this.$t('table_header.result'), value: 'is_pass', sortable: false, width: '150px' },
                { text: this.$t('table_header.score'), value: 'user_score', sortable: false, width: '200px' },
                {
                    text: this.$t('table_header.last_study_date'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                { text: this.$t('table_header.actions'), value: 'actions', sortable: false, width: '200px' },
            ],
            fieldsSearch: [
                {
                    title: '',
                    value: '',
                },
            ],
            selectedField: '',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
            testId: this.$route.query.test_id,
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('resultsSection', ['isLoadingResultsSection', 'resultsSections', 'resultsSectionsPagination']),
        formTitle() {
            return this.cuIndex === -1 ? 'New Item' : 'Edit Item';
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
    },
    async mounted() {
        await this.getUserList({ page: this.page, field: this.selectedField, search: this.search });
    },
    watch: {
        page(val) {
            this.getUserList({ page: val, field: this.selectedField, search: this.search });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
    },
    methods: {
        ...mapActions('resultsSection', [
            'getResultsSection',
            'postNewResultsSection',
            'putOneResultsSection',
            'deleteOneResultsSection',
        ]),
        async getUserList() {
            const { user_id, test_id, course_id } = this.$route.query;
            await this.getResultsSection({ user_id, test_id, course_id });
        },

        initialize() {
            this.getUserList({ page: 1, field: 'title', search: '' });
        },
        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },
        onAddNewItem() {
            this.dialogEdit = false;
            this.dialogCU = true;
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.resultsSections.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.resultsSections.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onDeleteItem(item) {
            this.cuIndex = this.resultsSections.indexOf(item);
            this.cuItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            // this.users.splice(this.cuIndex, 1);
            const res = await this.deleteOneUser(this.cuItem.id);
            if (res?.isSuccess) {
                await this.getUserList({ page: this.page, field: this.selectedField, search: this.search });
                this.showToast('success', res?.data?.message);
            } else {
                this.showError(res?.mess);
            }
            this.closeDelete();
        },
        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeNoti() {
            this.dialogNoti = false;
        },
        async onSaveItem() {
            this.dialogNoti = true;
            if (this.cuIndex === -1) {
                const res = await this.postNewUser(this.cuItem);
                if (res?.isSuccess) {
                    this.formMessage = res?.mess || 'Created success!';
                    await this.getUserList({ page: 1, field: 'title', search: '' });
                } else {
                    this.formMessage = res?.mess || 'Created fail!';
                }
            } else {
                const res = await this.putOneUser(this.cuItem);
                if (res?.isSuccess) {
                    this.formMessage = res?.mess || 'Updated success!';
                    await this.getUserList({ page: this.page, field: this.selectedField, search: this.search });
                } else {
                    this.formMessage = res?.mess || 'Updated fail!';
                }
            }
        },
        onSearchItem: _.debounce(function (e) {
            this.getUserList({ page: 1, field: this.selectedField, search: this.search });
        }, 500),
        handleChangeSelect(value) {
            this.getUserList({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.table-tag {
    min-width: 100px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}
</style>
